.form {
    background-color: var(--mantine-color-white);
    padding: var(--mantine-spacing-xl);
    border-radius: var(--mantine-radius-md);
    box-shadow: var(--mantine-shadow-lg);
}

.field {
    padding-top: var(--mantine-spacing-xs);
}

.alert {
    width: 100%;
}
