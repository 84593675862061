.wrapper {
  min-height: 100vh;
  background-image: linear-gradient(
    -60deg,
    var(--mantine-color-blue-2) 0%,
    var(--mantine-color-blue-8) 100%
  );
  padding: calc(var(--mantine-spacing-xl) * 2.5);

  @media (max-width: $mantine-breakpoint-sm) {
    padding: calc(var(--mantine-spacing-xl) * 1.5);
  }
}

.footerText {
  color: var(--mantine-color-blue-1);
  text-decoration: none;
}
